import { useMemo } from 'react';

import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

export function useMembershipExists(): boolean {
	const session = useSessionAsync();
	return useMemo(() => {
		if (!isLoaded(session)) {
			return true;
		}

		return session.value.hasMembership;
	}, [session]);
}
