import type { ComponentProps } from 'react';
import React, { useMemo } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { buttonResetStyles } from '@change-corgi/design-system/reset';

type Props = {
	amount: number;
	amountLocalized: string;
	isSelected: boolean;
	onClick: (value: { amount: number; amountLocalized: string }) => void;
};

export function AmountButton({
	amount,
	amountLocalized,
	isSelected,
	onClick,
	...rest
}: Props & Omit<ComponentProps<'button'>, 'onClick'>): React.JSX.Element {
	const { translate } = useI18n();

	const value = useMemo(() => {
		return amount ? amountLocalized : translate('corgi.membership.awareness.other_amount_label');
	}, [amount, amountLocalized, translate]);
	return (
		<button
			type="button"
			data-testid="membership-awareness-form-amount-button"
			onClick={() => onClick({ amount, amountLocalized })}
			data-selected={isSelected}
			sx={{
				...buttonResetStyles,
				borderColor: 'neutral-grey600',
				borderStyle: 'solid',
				borderWidth: 1,
				px: 16,
				color: 'unset',
				backgroundColor: 'unset',
				maxWidth: '100%',
				fontWeight: 'bold',
				borderRadius: 'standard',
				height: 48,
				fontSize: 14,
				cursor: 'pointer',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&[data-selected=true]': {
					borderColor: 'primary-changeRed',
					borderWidth: 2,
					px: 15,
					color: 'primary-changeRed',
				},
			}}
			{...rest}
		>
			{value}
		</button>
	);
}
