import {
	FCM_ENABLE_SUBSCRIPTIONS_FOR,
	FCM_REVENUE_SUBS_ONE_TIME_CONTRIBUTIONS,
} from '@change-corgi/config/fcm/configs';

import { createFcmContext } from 'src/app/shared/contexts/fcm';
import {
	FCM_MEMBERSHIP_IOS_AMOUNTS_ENABLED_FOR,
	FCM_MEMBERSHIP_YEARLY_CONTRIBUTIONS_AMOUNTS,
	FCM_SUBSCRIPTION_CONFIGURATION,
} from 'src/app/shared/fcm/configs';
import type { LoadedStateData } from 'src/app/shared/utils/async';

export const {
	FcmContext: MembershipAwarenessFormFcmContext,
	FcmProvider: MembershipAwarenessFormFcmContextProvider,
	useFcm: useMembershipAwarenessFormFcm,
	useAsyncFcm: useAsyncMembershipAwarenessFormFcm,
	prefetchFcm: prefetchMembershipAwarenessFormFcm,
} = createFcmContext({
	name: 'MembershipAwarenessFormFcmContext',
	dataProperty: 'formFcm',
	configs: {
		membershipEnabled: FCM_ENABLE_SUBSCRIPTIONS_FOR,
		subscriptionConfig: FCM_SUBSCRIPTION_CONFIGURATION,
		iosAmountsEnabled: FCM_MEMBERSHIP_IOS_AMOUNTS_ENABLED_FOR,
		yearlyContributionsAmounts: FCM_MEMBERSHIP_YEARLY_CONTRIBUTIONS_AMOUNTS,
		oneTimeContributionsEnabled: FCM_REVENUE_SUBS_ONE_TIME_CONTRIBUTIONS,
	},
	prefetch: true,
});

export type MembershipAwarenessFormFcmState = ReturnType<typeof useAsyncMembershipAwarenessFormFcm>;
export type MembershipAwarenessFormFcm = LoadedStateData<MembershipAwarenessFormFcmState>;
