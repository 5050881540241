import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { FCM_SUBSCRIPTION_CONFIGURATION } from 'src/app/shared/fcm/configs';

import type { MembershipAwarenessFormData as MembershipAwarenessFormDataType } from '../types';

import { MembershipAwarenessFormData, type MembershipAwarenessFormDataQuery } from './getMembershipFormData.graphql';
import { normalize } from './normalize';

export async function getMembershipFormData(utilityContext: UtilityContext): Promise<MembershipAwarenessFormDataType> {
	const {
		gql: { fetch },
	} = utilityContext;

	const [{ data }, { subscriptionConfig }] = await Promise.all([
		fetch<MembershipAwarenessFormDataQuery>({
			query: MembershipAwarenessFormData,
			rejectOnError: true,
		}),
		utilityContext.fcm.get({
			subscriptionConfig: FCM_SUBSCRIPTION_CONFIGURATION,
		}),
	]);

	if (!data) {
		throw new Error('Unexpected empty data in MembershipAwarenessFormData');
	}

	if (!subscriptionConfig) {
		throw new Error('Subscription Configuration FC not setup for user country');
	}

	return normalize(utilityContext, { data, subscriptionConfig });
}
