import type { MembershipAwarenessFormState } from './types';

export const mutations = {
	setPaymentTerm(
		state: MembershipAwarenessFormState,
		value: 'one-time' | 'monthly' | 'yearly',
	): MembershipAwarenessFormState {
		return {
			...state,
			paymentTerm: value,
		};
	},
};
