import { createFcmExperimentsContext } from 'src/app/shared/contexts/experiment';
import type { LoadedStateData } from 'src/app/shared/utils/async';

import {
	FCM_EXPERIMENT_MEMBERSHIP_AWARENESS_PLACEMENT_AB_EXPERIMENT,
	FCM_EXPERIMENT_MEMBERSHIP_YEARLY_CONTRIBUTIONS,
} from './config';

export const {
	FcmExperimentsContext: MembershipAwarenessFormFcmExperimentsContext,
	FcmExperimentsProvider: MembershipAwarenessFormFcmExperimentsContextProvider,
	useFcmExperiments: useMembershipAwarenessFormFcmExperiments,
	useAsyncFcmExperiments: useAsyncMembershipAwarenessFormFcmExperiments,
	prefetchFcmExperiments: prefetchMembershipAwarenessFormFcmExperiments,
} = createFcmExperimentsContext({
	name: 'MembershipAwarenessFormFcmExperimentsContext',
	dataProperty: 'formFcmExperiments',
	configs: {
		defaultAmountExperiment: FCM_EXPERIMENT_MEMBERSHIP_AWARENESS_PLACEMENT_AB_EXPERIMENT,
		showYearlyContributions: FCM_EXPERIMENT_MEMBERSHIP_YEARLY_CONTRIBUTIONS,
	},
	prefetch: true,
});

export type MembershipAwarenessFormFcmExperimentsState = ReturnType<
	typeof useAsyncMembershipAwarenessFormFcmExperiments
>;
export type MembershipAwarenessFormFcmExperiments = LoadedStateData<MembershipAwarenessFormFcmExperimentsState>;
