import type { ChangeEvent } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useTracking } from '@change-corgi/core/react/tracking';

import { useMembershipAwarenessFormFcm } from '../../../formContext';
import { useMembershipAwarenessFormFcmExperiments } from '../../../formContext/formFcmExperiments';
import { useMembershipAwarenessFormState } from '../../../formContext/formState';

type Result = ModelHookResult<
	{
		headlinePart1: string;
		headlinePart2: string;
		body: string;
		ctaQuestion: string;
		heroImage: string;
		paymentTerm: 'one-time' | 'monthly' | 'yearly';
		showYearlyPaymentTerm: boolean;
		showOneTimePaymentTerm: boolean;
	},
	{
		onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	}
>;

type PaymentTerm = 'one-time' | 'monthly' | 'yearly';

export function useMembershipAwarenessPlacementConfig(): Result {
	const { translate } = useI18n();
	const { showYearlyContributions } = useMembershipAwarenessFormFcmExperiments();
	const [{ paymentTerm }, { setPaymentTerm }] = useMembershipAwarenessFormState();
	const { oneTimeContributionsEnabled } = useMembershipAwarenessFormFcm();
	const track = useTracking();

	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		setPaymentTerm(event.target.value as PaymentTerm);
		void track('membership_ask_payment_term_selected', { payment_term: event.target.value });
	};
	return {
		data: {
			headlinePart1: translate('corgi.membership.awareness.headline_part1'),
			headlinePart2: translate('corgi.membership.awareness.headline_part2'),
			body: translate('corgi.membership.awareness.description'),
			ctaQuestion: translate('corgi.membership.awareness.ask'),
			heroImage: 'https://static.change.org/membership/main-image.png',
			showYearlyPaymentTerm: showYearlyContributions.variation !== 'control',
			showOneTimePaymentTerm: oneTimeContributionsEnabled,
			paymentTerm,
		},
		actions: {
			onChange,
		},
	};
}
