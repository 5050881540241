import type { PropsWithChildren } from 'react';

import { TrackingContext } from '@change-corgi/core/react/tracking';

import { isLoaded } from 'src/app/shared/utils/async';

import { MembershipAwarenessFormDataContextProvider } from './formData';
import { MembershipAwarenessFormFcmContextProvider } from './formFcm';
import { MembershipAwarenessFormFcmExperimentsContextProvider } from './formFcmExperiments';
import { MembershipAwarenessFormStateContextProvider } from './formState';
import type { MembershipAwarenessFormPrefetchedContext } from './hook';
import { useFormContext } from './hook';

type Props = {
	prefetchedData: MembershipAwarenessFormPrefetchedContext | undefined;
	petitionId?: string;
};

export function MembershipAwarenessFormContextProvider({
	children,
	prefetchedData,
	petitionId,
}: PropsWithChildren<Props>): React.JSX.Element | null {
	const formContextState = useFormContext(prefetchedData);

	// not displaying a loader when in a loading state because it might result in nothing
	if (!isLoaded(formContextState)) return null;

	const { formData, formFcm, formFcmExperiments } = formContextState;

	return (
		<MembershipAwarenessFormDataContextProvider formData={formData}>
			<MembershipAwarenessFormFcmContextProvider formFcm={formFcm}>
				<MembershipAwarenessFormFcmExperimentsContextProvider formFcmExperiments={formFcmExperiments}>
					<MembershipAwarenessFormStateContextProvider>
						<TrackingContext properties={{ petition_id: petitionId }}>{children}</TrackingContext>
					</MembershipAwarenessFormStateContextProvider>
				</MembershipAwarenessFormFcmExperimentsContextProvider>
			</MembershipAwarenessFormFcmContextProvider>
		</MembershipAwarenessFormDataContextProvider>
	);
}
