import { createPrefetchableAsyncDataContext } from 'src/app/shared/contexts/asyncData';

import { getMembershipAwarenessFormData } from './api';

export const {
	Context: MembershipAwarenessFormDataContext,
	Provider: MembershipAwarenessFormDataContextProvider,
	useContext: useMembershipAwarenessFormData,
	useAsyncData: useMembershipAwarenessFormDataFromApi,
	prefetchAsyncData: prefetchMembershipAwarenessFormDataFromApi,
} = createPrefetchableAsyncDataContext({
	name: 'MembershipAwarenessFormDataContext',
	oldVersion: true,
	dataProperty: 'formData',
	hasUserData: true,
	getData: getMembershipAwarenessFormData,
});
