import { createStateContext } from '@change-corgi/core/react/context';

import { initialState } from './initialState';
import { mutations } from './mutations';
import type { MembershipAwarenessFormState } from './types';

export const {
	StateContext: MembershipAwarenessFormStateContext,
	StateProvider: MembershipAwarenessFormStateContextProvider,
	useStateContext: useMembershipAwarenessFormState,
} = createStateContext<MembershipAwarenessFormState, typeof mutations>({
	name: 'MembershipAwarenessFormStateContext',
	mutations,
	initialState: { ...initialState() },
});
