import { MembershipAwareness } from '../components/MembershipAwareness';
import { useMembershipAwarenessFormFcm } from '../formContext';
import { useMembershipExists } from '../shared/hooks/useMembershipExists';
import type { Props } from '../shared/types';

export function MembershipAwarenessContainer({ sourceLocation, slug }: Props): React.JSX.Element | null {
	const { membershipEnabled } = useMembershipAwarenessFormFcm();
	const membershipExists = useMembershipExists();

	if (!membershipEnabled || membershipExists) return null;
	return <MembershipAwareness sourceLocation={sourceLocation} slug={slug} />;
}
